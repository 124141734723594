<template>
  <el-form ref='form' :model="data" :rules='rules'>
    <div class="form-row">
      <el-form-item label="Full Name" prop="name" :error="errors.name" class="col-md-12">
        <el-input v-model="data.name" name="name" placeholder="Enter firm name" autocomplete="off"></el-input>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Description" prop="description" :error="errors.description" class="col-md-12">
        <el-input type="textarea" name="description" v-model="data.description" rows="5" placeholder="Enter a description of the firm"></el-input>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Regions" prop="regions" :error="errors.region" class="col-md-12">
        <el-select v-model="data.regions" name="regions" placeholder="Select Region"  class="w-100" multiple filterable>
          <el-option v-for="(t, i) in available_regions" :key="i" :label="t.label" :value="t.id" :disabled="t.disabled"></el-option>
        </el-select>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Enumeration Start date" prop="start" class="col-md-4">
        <el-date-picker
          v-model="data.start"
          type="date"
          align="right"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="Enumeration End date" prop="end" class="col-md-4">
        <el-date-picker
          v-model="data.end"
          type="date"
          align="left"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="Enumerator Daily Target" prop="daily_target" :error="errors.daily_target" class="col-md-4">
        <el-input-number v-model="data.daily_target" name="daily_target" placeholder="Enter daily target" :min="1"></el-input-number>
      </el-form-item>
    </div>

  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import is from 'is_js'
import { bus } from '../../../main'

export default {
  name: 'FirmForm',
  props: {
    inStateOne: Boolean,
    takenRegions: { type: Array, default: () => { return [] } }
  },
  data () {
    let validateName = (rule, value, callback) => {
      if (!is.empty(value) && value.indexOf(' ') !== -1) {
        callback()
      } else {
        callback(new Error('Firm name is required.'))
      }
    }
    let validateStart = (rule, value, callback) => {
      let s = new Date(value)
      if (is.not.nan(s.getTime())) {
        callback()
      } else {
        callback(new Error('Start date is required.'))
      }
    }
    let validateEnd = (rule, value, callback) => {
      let e = new Date(value)
      if (is.nan(e.getTime())) {
        callback(new Error('End date has to be after start date.'))
      }
      let s = new Date(this.data.start)
      if (is.not.nan(s.getTime())) {
        if (s > e) {
          callback(new Error('End date has to be after start date.'))
        }
      }
      callback()
    }
    let validateDesc = (rule, value, callback) => {
      if (is.not.empty(value)) {
        callback()
      } else {
        callback(new Error('Description is required.'))
      }
    }
    return {
      data: {},
      rules: {
        regions: [
          { required: true, message: 'Regions cannot be empty', trigger: 'change' }
        ],
        name: [
          { required: true, type: 'string', message: 'Name cannot be empty', trigger: 'blur' },
          { validator: validateName, trigger: 'change' }
        ],
        description: [
          { required: true, message: 'Description can not be empty', trigger: 'blur' },
          { validator: validateDesc, trigger: 'blur' }
        ],
        start: [
          { required: true, message: 'Stat date cannot be empty', trigger: 'blur' },
          { validator: validateStart, trigger: 'blur' }
        ],
        end: [
          { required: true, message: 'End date has to be after start date.', trigger: 'blur' },
          { validator: validateEnd, trigger: 'blur' }
        ]
      },
      errors: {
        name: '',
        start: '',
        end: '',
        regions: [],
        daily_target: '',
        description: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      sys_regions: 'getRegionsDropdown'
    }),
    available_regions: function () {
      let self = this
      return this.sys_regions.map(function (r) {
        r.disabled = is.inArray(r.id, self.takenRegions)
        return r
      })
    },
    defaultData: function () {
      return {
        id: '',
        name: '',
        description: '',
        active: 1,
        start: '',
        end: '',
        daily_target: 10,
        regions: []
      }
    }
  },
  created () {
    bus.$on('sserror', (data) => {
      for (let x in this.errors) {
        this.errors[x] = ''
      }
      if (is.existy(data) && is.existy(data.errors)) {
        let errors = data.errors
        for (let x in errors) {
          this.errors[x] = errors[x][0]
        }
      }
    })
  }
}
</script>
