<template>
  <el-form ref='form' :model="data" :rules='rules'>
    <div class="form-row">
      <el-form-item label="Name" prop="region" :error="errors.region" class="col-md-12">
        <el-input v-model="data.region" name="region" placeholder="Enter region name" autocomplete="off"></el-input>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Capital" prop="capital" :error="errors.capital" class="col-md-12">
        <el-input v-model="data.capital" name="name" placeholder="Enter region capital name" autocomplete="off"></el-input>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Notes" prop="Notes" :error="errors.notes" class="col-md-12">
        <el-input type="textarea" name="notes" v-model="data.notes" rows="5" placeholder="Enter a notes for the region"></el-input>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import is from 'is_js'
import { bus } from '../../../main'

export default {
  name: 'RegionForm',
  props: {
    inStateOne: Boolean
  },
  data () {
    let validateName = (rule, value, callback) => {
      if (!is.empty(value) && value.indexOf(' ') !== -1) {
        callback()
      } else {
        callback(new Error('Region name is required.'))
      }
    }
    let validateCapital = (rule, value, callback) => {
      if (!is.empty(value)) {
        callback()
      } else {
        callback(new Error('Capital is required.'))
      }
    }
    let validateDesc = (rule, value, callback) => {
      if (is.not.empty(value)) {
        callback()
      } else {
        callback(new Error('Notes is required.'))
      }
    }
    return {
      data: {},
      rules: {
        region: [
          { required: true, type: 'string', message: 'Name cannot be empty', trigger: 'blur' },
          { validator: validateName, trigger: 'change' }
        ],
        capital: [
          { required: true, type: 'string', message: 'Capital cannot be empty', trigger: 'blur' },
          { validator: validateCapital, trigger: 'change' }
        ],
        notes: [
          { required: true, message: 'Notes can not be empty', trigger: 'blur' },
          { validator: validateDesc, trigger: 'blur' }
        ]
      },
      errors: {
        region: '',
        capital: '',
        notes: ''
      }
    }
  },
  computed: {
    defaultData: function () {
      return {
        regionCode: '',
        region: '',
        capital: '',
        notes: ''
      }
    }
  },
  created () {
    bus.$on('sserror', (data) => {
      for (let x in this.errors) {
        this.errors[x] = ''
      }
      if (is.existy(data) && is.existy(data.errors)) {
        let errors = data.errors
        for (let x in errors) {
          this.errors[x] = errors[x][0]
        }
      }
    })
  }
}
</script>
