import GroupForm from './GroupForm'
import { createFormDialog } from 'el-form-dialog'
import service from '../../../api/user'
import { bus } from '../../../main'

export default createFormDialog(
  {
    stateOneTitle: 'Add Group',
    stateTwoTitle: 'Edit Group',
    confirmText: 'Ok',
    cancelText: 'Cancel',
    async confirm(data) {
      this.showLoading()

      try {
        if (this.inStateOne) {
          await this.addGroup(data)
        } else {
          await this.updateGroup(data)
        }
      } catch (e) {
        this.syslog(e)
      }
    }
  },
  {
    methods: {
      removeFields (data) {
        let fields = []
        fields.forEach(function (f) {
          delete data[f]
        })
        return data
      },
      addGroup (data) {
        let self = this
        data = this.removeFields(data)
        return service.addGroup(data)
          .then((response) => {
            this.$emit('updated', response.data)
            self.hideLoading()
            self.closeDialog()
          })
          .catch((error) => {
            this.hideLoading()
            bus.$emit('sserror', error)
          })
      },
      updateGroup (data) {
        let self = this
        data = this.removeFields(data)
        return service.updateGroup(data)
          .then((response) => {
            this.$emit('updated', response)
            self.hideLoading()
            self.closeDialog()
          })
          .catch((error) => {
            this.hideLoading()
            bus.$emit('sserror', error)
          })
      }
    }
  }
)(GroupForm)