import CommunityForm from './CommunityForm'
import { createFormDialog } from 'el-form-dialog'
import service from '../../../api/locations'
import { bus } from '../../../main'

export default createFormDialog(
  {
    stateOneTitle: 'Add Community',
    stateTwoTitle: 'Edit Community',
    confirmText: 'Ok',
    cancelText: 'Cancel',
    async confirm(data) {
      this.showLoading()

      try {
        if (this.inStateOne) {
          await this.addCommunity(data)
        } else {
          await this.updateCommunity(data)
        }
      } catch (e) {
        this.syslog(e)
      }
    }
  },
  {
    methods: {
      removeFields (data) {
        let fields = ['performance', 'enumerationActual', 'district', 'region']
        fields.forEach(function (f) {
          delete data[f]
        })
        return data
      },
      addCommunity (data) {
        let self = this
        data = this.removeFields(data)
        return service.addCommunity(data)
          .then((response) => {
            this.$emit('updated', response.data)
            self.hideLoading()
            self.closeDialog()
          })
          .catch((error) => {
            this.hideLoading()
            bus.$emit('sserror', error)
          })
      },
      updateCommunity (data) {
        let self = this
        data = this.removeFields(data)
        return service.updateCommunity(data)
          .then((response) => {
            this.$emit('updated', response)
            self.hideLoading()
            self.closeDialog()
          })
          .catch((error) => {
            this.hideLoading()
            bus.$emit('sserror', error)
          })
      }
    }
  }
)(CommunityForm)