import is from 'is_js'
import UserForm from './UserForm'
import { createFormDialog } from 'el-form-dialog'
import userService from '../../../api/user'
import { bus } from '../../../main'

export default createFormDialog(
  {
    stateOneTitle: 'Add User',
    stateTwoTitle: 'Edit User',
    confirmText: 'Ok',
    cancelText: 'Cancel',
    async confirm(data) {
      // console.log('Submitting')
      // console.log(data)
      this.showLoading()
      try {
        // this.testU(data)
        if (this.inStateOne) {
          await this.addUser(data)
        } else {
          await this.updateUser(data)
        }
      } catch (e) {
        this.syslog(e)
      }
    }
  },
  {
    methods: {
      prepData(data) {
        if (is.empty(data.description)) {
          delete data['description']
        }
        if (is.empty(data.email) && is.inArray(data.account_type, [4, 5])) {
          data['email'] = data['mobile'] + '@' + data['firm_id'] + '.com'
        }
        return data
      },
      testU(data) {
        data = this.prepData(data)
        console.log(data)
        this.hideLoading()
      },
      addUser(data) {
        let self = this
        data = this.prepData(data)
        return userService
          .addUser(data)
          .then((response) => {
            this.$emit('updated', response.data)
            self.hideLoading()
            self.closeDialog()
          })
          .catch((error) => {
            this.hideLoading()
            bus.$emit('sserror', error)
          })
      },
      updateUser(data) {
        let self = this
        data = this.prepData(data)
        if (is.undefined(data.password) || is.empty(data.password)) {
          delete data.password
          delete data.password_confirmation
        }
        return userService
          .updateUser(data)
          .then((response) => {
            this.$emit('updated', response)
            self.hideLoading()
            self.closeDialog()
          })
          .catch((error) => {
            this.hideLoading()
            bus.$emit('sserror', error)
          })
      }
    }
  }
)(UserForm)
