<template>
  <div style="margin:0; padding:0">
    <PageHeader subtitle="Manage Locations" :title="mainTitle" />

    <b-tabs content-class="mt-3" v-model="activeTab" active-nav-item-class="font-weight-bold text-primary" fill>
      <b-tab :title="regionTitle">
        <template v-slot:title>
          <b-spinner small variant="danger" v-show="regionLoading"></b-spinner> {{regionTitle}}
        </template>
        <Region v-on:busy="onBusyChange" v-on:title="onTitleChange" />
      </b-tab>
     <b-tab :title="districtTitle">
        <template v-slot:title>
          <b-spinner small variant="danger" v-show="districtLoading"></b-spinner> {{districtTitle}}
        </template>
        <District v-on:busy="onBusyChange" v-on:title="onTitleChange" />
      </b-tab>
      <b-tab :title="communityTitle">
        <template v-slot:title>
          <b-spinner small variant="danger" v-show="communityLoading"></b-spinner> {{communityTitle}}
        </template>
        <Community v-on:busy="onBusyChange" v-on:title="onTitleChange" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import PageHeader from '../../containers/PageHeader'
import Region from '../../components/locations/Region'
import District from '../../components/locations/District'
import Community from '../../components/locations/Community'

export default {
  name: 'LocationManagement',
  components: {
    PageHeader, Region, District, Community
  },
  data () {
    return {
      activeTab: 0,
      mainTitle: 'Regions',
      regionTitle: 'Regions',
      districtTitle: 'Districts',
      communityTitle: 'Communities',
      regionLoading: false,
      districtLoading: false,
      communityLoading: false,
    }
  },
  created () {
  },
  watch: {
    activeTab: function (v) {
      this.mainTitle = (v === 0) ? this.regionTitle : ((v === 1)  ? this.districtTitle : this.communityTitle)
    },
    regionTitle: function (v) {
      this.mainTitle = (this.activeTab === 0) ? v : this.mainTitle
    },
    districtTitle: function (v) {
      this.mainTitle = (this.activeTab === 1) ? v : this.mainTitle
    },
    communityTitle: function (v) {
      this.mainTitle = (this.activeTab === 2) ? v : this.mainTitle
    }
  },
  methods: {
    onBusyChange (arg) {
      switch(arg.type) {
        case 'region':
          this.regionLoading = arg.value
          break
        case 'district':
          this.districtLoading = arg.value
          break
        case 'community':
          this.communityLoading = arg.value
          break
        default:
      }
    },
    onTitleChange (arg) {
      switch(arg.type) {
        case 'region':
          this.regionTitle = arg.value
          break
        case 'district':
          this.districtTitle = arg.value
          break
        case 'community':
          this.communityTitle = arg.value
          break
        default:
      }
    }
  }
}
</script>
