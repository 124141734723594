<template>
  <div>
    <b-form-group id="district_combo" description="" label-class="font-weight-bolder" label="Districts" :label-cols="lpos" label-for="district_select" :state="null">
      <model-list-select id="districts_select" name="districts_select" :list="districts" v-model="selected" placeholder="Select District" option-value="id" option-text="label"></model-list-select>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'

export default {
  name: 'DistrictFilter',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: Number | String, default: 0 },
    regionId: { type: Number | String, default: 0 },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    first: true,
    selected: ''
  }),
  computed: {
    ...mapGetters({
      options: 'getDistrictsDropdown'
    }),
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    },
    districts: function () {
      let self = this
      let seen = []
      let retVal = []
      let x = this.options.filter((o) => {
        return self.regionId === 0 || self.regionId === o.region
      })
      x.forEach((e) => {
        if (!seen.includes(e.id)) {
          retVal.push(e)
          seen.push(e.id)
        }
      })
      return retVal
    }
  },
  watch: {
    selected: function () {
      this.sendUpdate()
    },
    regionId: function (v) {}
  },
  created() {
    this.selected = this.id
  },
  methods: {
    sendUpdate() {
      if (!this.first) {
        this.$emit('district_change', { value: this.selected })
      } else {
        this.first = false
      }
    }
  }
}
</script>
