<template>
  <div>
    <b-form-group id="region_combo" description="" label="Regions" :label-cols="lpos" label-class="font-weight-bolder" label-for="region_select" :state="null">
      <model-list-select id="regions_select" name="regions_select" :list="options" v-model="selected" placeholder="Select Region" option-value="id" option-text="label"></model-list-select>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'

export default {
  name: 'RegionFilter',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: Number, default: 0 },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    first: true,
    selected: ''
  }),
  computed: {
    ...mapGetters({
      options: 'getRegionsDropdown'
    }),
    lpos: function () {
      return this.labelPosition === 'left' ? 'auto' : 0
    }
  },
  watch: {
    selected: function () {
      this.sendUpdate()
    }
  },
  created() {
    this.selected = this.id
  },
  methods: {
    sendUpdate() {
      if (!this.first) {
        this.$emit('region_change', { value: this.selected })
      } else {
        this.first = false
      }
    }
  }
}
</script>
