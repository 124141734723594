<template>
  <div style="margin: 0; padding: 0">
    <PageHeader subtitle="Manage Firms" :title="title" />

    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom">
            <div class="row">
              <div class="col-sm-5">
                <div class="btn-group mr-1" data-toggle="buttons" aria-label="">
                  <el-input v-model="tableFilteredData[0].value" placeholder="Search" prefix-icon="el-icon-search" size="medium" style="width: 500px"></el-input>
                </div>
              </div>
              <div class="col-sm-7 hidden-sm-down">
                <div class="btn-toolbar float-right" role="toolbar" aria-label="Toolbar with button groups">
                  <el-button type="primary" size="mini" @click="addFirm()"><i class="el-icon-plus el-icon--left"></i> Add New Firm</el-button>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body p-4 pb-3">
            <data-tables-server :loading="busy" :data="tableData" :total="tableTotal" :filters="tableFilteredData" @query-change="getFirms" :table-props="tableProps">
              <el-table-column prop="name" label="Name" sortable="custom"></el-table-column>
              <el-table-column prop="description" label="Description" sortable="custom"></el-table-column>
              <el-table-column prop="active_text" label="Active" sortable="custom"></el-table-column>
              <el-table-column prop="region_list" label="Regions"></el-table-column>
              <el-table-column prop="daily_target" label="Enumerator Daily Target"></el-table-column>

              <el-table-column align="left" label="">
                <template slot-scope="scope">
                  <el-button v-show="scope.row.id !== 1" size="mini" icon="el-icon-edit" type="primary" @click="editFirm(scope.$index, scope.row)"></el-button>
                  <el-button v-show="scope.row.id !== 1" size="mini" icon="el-icon-delete" type="danger" @click="deleteFirm(scope.$index, scope.row)"></el-button>
                </template>
              </el-table-column>
            </data-tables-server>
          </div>
        </div>
      </div>
    </div>

    <Firm-form :data="editData" :taken-regions="usedRegions" :in-state-one="addState" :visible.sync="dialogOpen" :dialogProps="{ closeOnClickModal: false }" v-on:updated="addEditUpdate"> </Firm-form>
  </div>
</template>

<script>
import Vue from 'vue'
import is from 'is_js'
import PageHeader from '../../containers/PageHeader'
import FirmForm from '../../components/forms/FirmForm'
import { DataTablesServer } from 'vue-data-tables'
import firmService from '../../api/firm'

Vue.use(DataTablesServer)

export default {
  name: 'FirmManagement',
  components: {
    PageHeader,
    FirmForm
  },
  data() {
    return {
      busy: true,
      addState: true,
      dialogOpen: false,
      editData: {},
      editIndex: null,
      emptyText: 'No data found',
      usedRegions: [],
      tableData: [],
      tableTotal: 0,
      tableFilteredData: [
        {
          value: '',
          prop: ['name', 'description', 'active', 'region_list']
        }
      ],
      tableProps: { style: 'width: 99%', emptyText: 'No Firms found', stripe: true, defaultSort: { order: 'ascending', prop: 'name' } },
      queryProps: { page: 1, pageSize: 10, sort: { order: 'ascending', prop: 'name' }, search: '' }
    }
  },
  created() {
    this.getFirms()
  },
  computed: {
    title: function () {
      return 'Firms (' + this.tableTotal + ')'
    }
  },
  methods: {
    getFirms(queryInfo = {}) {
      let self = this
      this.busy = true
      let params = this.getParams(queryInfo)
      firmService
        .getFirms(params)
        .then((response) => {
          self.tableData = response.data.map(function (s) {
            return self.getSimpleFirm(s)
          })
          self.updateAvailableRegions()
          self.tableTotal = response.meta.total
          self.busy = false
        })
        .catch(() => {
          this.busy = false
          // handleError(error)
        })
    },
    addFirm() {
      this.addState = true
      this.dialogOpen = true
    },
    editFirm(idx, Firm) {
      this.editIndex = idx
      this.editData = Firm
      this.addState = false
      this.dialogOpen = true
      // console.log(Firm)
    },
    addEditUpdate(Firm) {
      let s = this.getSimpleFirm(Firm)
      if (this.addState) {
        this.tableData.unshift(s)
        this.showSuccess('Firm added', 'Firm has been successfully added.')
      } else {
        Vue.set(this.tableData, this.editIndex, s)
        this.showSuccess('Firm updated', 'Firm has been successfully saved.')
      }
      this.$store.dispatch('getFirms', { limit: -1 })
      this.updateAvailableRegions()
    },
    deleteFirm(idx, Firm) {
      let self = this
      let name = Firm.name
      this.editIndex = idx
      self
        .$confirm('This will permanently delete ' + name + '. Continue?', 'Warning', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        })
        .then(() => {
          firmService
            .removeFirm(Firm)
            .then(() => {
              self.tableData.splice(self.editIndex, 1)
              self.editIndex = null
              self.showSuccess('Success', name + ' Deleted')
            })
            .catch(() => {
              self.editIndex = null
              return false
            })
        })
        .catch(() => {})
    },
    getParams(info) {
      return {
        page: info.page,
        limit: info.pageSize,
        sort_field: is.not.undefined(info.sort) && is.not.undefined(info.sort.prop) && is.not.empty(info.sort.prop) ? info.sort.prop : 'name',
        sort_order: is.not.undefined(info.sort) && is.not.undefined(info.sort.order) && is.not.empty(info.sort.order) ? info.sort.order : 'ascending',
        search: info.type === 'filter' ? (is.not.undefined(info.filters[0]) ? info.filters[0]['value'] : '') : ''
      }
    },
    getSimpleFirm(firm) {
      let x = firm
      x.active_text = firm.active === 1 || firm.active === true ? 'Yes' : 'No'
      x.active = firm.active === 1
      return x
    },
    updateAvailableRegions() {
      let self = this
      this.usedRegions = []
      this.tableData.forEach(function (r) {
        if (r.id !== 1) {
          self.usedRegions.push(r.regions[0])
        }
      })
    }
  }
}
</script>
