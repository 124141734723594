<template lang="pug">
    extends ../BaseView.pug
</template>

<script>
import Vue from 'vue'
import BaseView from '../BaseView'

import UserManagement from './UserManagement'
import GroupManagement from './GroupManagement'
import FirmManagement from './FirmManagement'
import LocationManagement from './LocationManagement'
import QuestionManagement from './QuestionManagement'

Vue.component('userManagement', { template: '<UserManagement />' })
Vue.component('groupManagement', { template: '<GroupManagement />' })
Vue.component('firmManagement', { template: '<FirmManagement />' })
Vue.component('locationManagement', { template: '<LocationManagement />' })
Vue.component('questionManagement', { template: '<QuestionManagement />' })

export default {
  name: 'System',
  extends: BaseView,
  components: { UserManagement, FirmManagement, LocationManagement, GroupManagement, QuestionManagement },
  props: {
    pages: {
      type: Array,
      default: () => {
        return [
          {
            tabName: 'users',
            component: 'userManagement'
          },
          {
            tabName: 'groups',
            component: 'groupManagement'
          },
          {
            tabName: 'firms',
            component: 'firmManagement'
          },
          {
            tabName: 'locations',
            component: 'locationManagement'
          },
          {
            tabName: 'questions',
            component: 'questionManagement'
          }
        ]
      }
    }
  }
}
</script>
