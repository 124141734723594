<template>
  <div style="margin: 0; padding: 0">
    <PageHeader subtitle="Manage QA Questions" :title="mainTitle" />

    <b-tabs content-class="mt-3" v-model="activeTab" active-nav-item-class="font-weight-bold text-primary" fill>
      <b-tab :title="householdTitle">
        <template v-slot:title> <b-spinner small variant="danger" v-show="householdLoading"></b-spinner> {{ householdTitle }} </template>
        <QAQuestion id="mx" v-on:busy="onBusyChange" v-on:title="onTitleChange" type="household" :questions="householdQuestions" />
      </b-tab>
      <b-tab :title="memberTitle">
        <template v-slot:title> <b-spinner small variant="danger" v-show="memberLoading"></b-spinner> {{ memberTitle }} </template>
        <QAQuestion id="yx" v-on:busy="onBusyChange" v-on:title="onTitleChange" type="member" :questions="memberQuestions" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import PageHeader from '../../containers/PageHeader'
import QAQuestion from '../../components/tables/QAQuestions'

import surveyService from '../../api/survey'

export default {
  name: 'QuestionManagement',
  components: {
    PageHeader,
    QAQuestion
  },
  data() {
    return {
      busy: false,
      activeTab: 0,
      mainTitle: 'Questions',
      householdTitle: 'Household Questions',
      memberTitle: 'Member Questions',
      questions: [],
      householdList: [],
      memberList: [],
      householdLoading: false,
      memberLoading: false
    }
  },
  computed: {
    householdQuestions: function () {
      return this.questions.filter((m) => m.code === 'UUID' || (m.code[0] !== 'B' && m.code[0] !== 'G'))
    },
    memberQuestions: function () {
      return this.questions.filter((m) => m.code === 'UUID' || m.code[0] === 'B' || m.code[0] === 'G')
    }
  },
  watch: {
    activeTab: function (v) {
      this.mainTitle = v === 0 ? this.householdTitle : this.memberTitle
    },
    householdTitle: function (v) {
      this.mainTitle = this.activeTab === 0 ? v : this.mainTitle
    },
    memberTitle: function (v) {
      this.mainTitle = this.activeTab === 1 ? v : this.mainTitle
    }
  },
  created() {
    this.getQuestions()
  },
  methods: {
    // API Call handlers
    getQuestions() {
      if (!this.busy) {
        let self = this
        this.busy = true
        surveyService
          .getQaQuestionRefs()
          .then((response) => {
            self.questions = response.data
            self.busy = false
          })
          .catch((error) => {
            this.busy = false
            this.handleError(error)
          })
      }
    },
    onBusyChange(arg) {
      switch (arg.type) {
        case 'household':
          this.householdLoading = arg.value
          break
        case 'member':
          this.memberLoading = arg.value
          break
        default:
      }
    },
    onTitleChange(arg) {
      switch (arg.type) {
        case 'household':
          this.householdTitle = arg.value
          break
        case 'member':
          this.memberTitle = arg.value
          break
        default:
      }
    }
  }
}
</script>
