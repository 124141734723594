<template>
  <div>
    <div v-show="busy">
      <b-spinner small class="ml-5" variant="primary" label="Loading people.." />
    </div>
    <div v-show="!busy">
      <b-form-group
        id="district_combo"
        description
        label="District"
        :label-cols="lpos"
        label-for="district_select"
        :state="null"
      >
        <template v-slot:label>
          <b>District</b>
        </template>
        <model-list-select
          id="district_select"
          name="district_select"
          :list="options"
          v-model="selected"
          placeholder="Select"
          option-value="id"
          option-text="label"
        ></model-list-select>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import { mapGetters } from 'vuex'

export default {
  name: 'SelectDeviceOwners',
  components: {
    ModelListSelect
  },
  props: {
    id: { type: Number },
    firm: { type: Number, default: 0 },
    value: { type: [Number, String], default: 0 },
    labelPosition: { type: String, default: 'left' }
  },
  data: () => ({
    busy: false,
    selected: null,
    options: []
  }),
  computed: {
    ...mapGetters({
      districts: 'getDistricts'
    }),
    lpos: function() {
      return this.labelPosition === 'left' ? 'auto' : 0
    }
  },
  watch: {
    selected: function() {
      this.sendUpdate()
    }
  },
  created() {
    this.selected = this.value
    this.setOptions()
  },
  methods: {
    sendUpdate() {
      this.$emit('input', this.selected)
      this.setOptions()
    },
    setOptions() {
      let self = this
      this.busy = true

      this.options = this.districts
        .filter(function() {
          return true
        })
        .map(function(e) {
          return { id: e.districtCode, label: e.district }
        })
        .sort(function(a, b) {
          return a.label.localeCompare(b.label)
        })
      self.busy = false
    }
  }
}
</script>
