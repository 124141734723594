import FirmForm from './FirmForm'
import { createFormDialog } from 'el-form-dialog'
import firmService from '../../../api/firm'
import { bus } from '../../../main'

export default createFormDialog(
  {
    stateOneTitle: 'Add Firm',
    stateTwoTitle: 'Edit Firm',
    confirmText: 'Ok',
    cancelText: 'Cancel',
    async confirm(data) {
      this.showLoading()

      try {
        if (this.inStateOne) {
          await this.addFirm(data)
        } else {
          await this.updateFirm(data)
        }
      } catch (e) {
        this.syslog(e)
      }
    }
  },
  {
    methods: {
      addFirm (data) {
        let self = this
        data.active = data.active === true ? 1 : data.active
        data.active = data.active === false ? 0 : data.active
        return firmService.addFirm(data)
          .then((response) => {
            this.$emit('updated', response.data)
            self.hideLoading()
            self.closeDialog()
          })
          .catch((error) => {
            this.hideLoading()
            bus.$emit('sserror', error)
          })
      },
      updateFirm (data) {
        let self = this
        data.active = data.active === true ? 1 : data.active
        data.active = data.active === false ? 0 : data.active
        return firmService.updateFirm(data)
          .then((response) => {
            this.$emit('updated', response)
            self.hideLoading()
            self.closeDialog()
          })
          .catch((error) => {
            this.hideLoading()
            bus.$emit('sserror', error)
          })
      }
    }
  }
)(FirmForm)