<template>
  <div style="margin:0; padding:0">

    <div class="row">
        <div class="col">
          <div class="card card-small mb-4">

            <div class="card-header border-bottom">
              <div class="row">
                    <div class="col-sm-3">
                      <regionFilter v-on:region_change="filterByRegion" />
                    </div>
                    <div class="col-sm-5">
                      <div class="btn-group mr-1" data-toggle="buttons" aria-label="">
                          <el-input v-model="tableFilteredData[0].value" placeholder="Search"
                              prefix-icon="el-icon-search" size="medium" style="width: 300px"></el-input>
                      </div>
                    </div>
                    <div class="col-sm-3 hidden-sm-down">
                        <div class="btn-toolbar float-right" role="toolbar" aria-label="Toolbar with button groups">
                          <!-- <el-button type="primary" size="mini" @click="addDistrict()"><i class="el-icon-plus el-icon--left"></i> Add New District</el-button> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body p-4 pb-3">
              <data-tables-server
                :loading='busy'
                :data='tableData'
                :total='tableTotal'
                :filters="tableFilteredData"
                @query-change="getDistricts"
                :table-props='tableProps'>

                <el-table-column prop='region' label='Region' sortable='custom'></el-table-column>
                <el-table-column prop='district' label='Name' sortable='custom'></el-table-column>
                <el-table-column prop='districtCapital' label='Capital' sortable='custom'></el-table-column>
                <el-table-column prop='notes' label='Notes' ortable='custom'></el-table-column>

                <!-- <el-table-column align="left" label="">
                    <template slot-scope="scope">
                      <el-button size="mini" icon="el-icon-edit" type="primary" @click="editDistrict(scope.$index, scope.row)"></el-button>
                      <el-button size="mini" icon="el-icon-delete" type="danger" @click="deleteDistrict(scope.$index, scope.row)"></el-button>
                    </template>
                  </el-table-column> -->

              </data-tables-server>
            </div>

          </div>
        </div>
    </div>
    <district-form
      :data='editData'
      :in-state-one='addState'
      :visible.sync='dialogOpen'
      :dialogProps="{ 'closeOnClickModal': false }"
      v-on:updated="addEditUpdate">
    </district-form>
  </div>
</template>

<script>
import Vue from 'vue'
import is from 'is_js'
import DistrictForm from '../../components/forms/DistrictForm'
import { DataTablesServer } from 'vue-data-tables'
import districtService from '../../api/locations'
import RegionFilter from '../../components/filters/RegionFilter'

Vue.use(DataTablesServer)

export default {
  name: 'DistrictManagement',
  components: {
    DistrictForm, RegionFilter
  },
  data () {
    return {
      busy: false,
      title: 'Districts',
      addState: true,
      dialogOpen: false,
      editData: {},
      editIndex: null,
      emptyText: 'No data found',
      tableData: [],
      tableTotal: 0,
      tableFilteredData: [
        {
          value: '',
          prop: ['district', 'districtCapital', 'notes', 'region']
        }
      ],
      tableProps: { style: 'width: 99%', emptyText: 'No districts found', stripe: true, defaultSort: {order: 'ascending', prop: 'region'} },
      queryProps: { page: 1, pageSize: 10, sort: {order: 'ascending', prop: 'region'}, search: '' },
      regionFilter: 0
    }
  },
  created () {
    this.getDistricts()
  },
  watch: {
    busy: function (v) {
      this.$emit('busy', { type: 'district', value: v })
    },
    title: function (v) {
      this.$emit('title', { type: 'district', value: v })
    }
  },
  methods: {
    getDistricts (queryInfo = {}) {
      let self = this
      this.busy = true
      let params = this.getParams(queryInfo)
      districtService.getDistricts(params)
        .then((response) => {
          self.tableData = response.data.map(function (s) {
            return self.getSimpleDistrict(s)
          })
          self.tableTotal = response.meta.total
          self.title = 'Districts (' + this.tableTotal + ')'
          self.busy = false
        })
        .catch((error) => {
          this.busy = false
          this.title = 'Districts'
          this.handleError(error)
        })
    },
    addDistrict () {
      this.addState = true
      this.dialogOpen = true
    },
    editDistrict (idx, district) {
      this.editIndex = idx
      this.editData = district
      this.addState = false
      this.dialogOpen = true
    },
    addEditUpdate (district) {
      let s = this.getSimpleDistrict(district)
      if (this.addState) {
        this.tableData.unshift(s)
        this.title = 'Districts (' + (this.tableTotal + 1) + ')'
        this.showSuccess('District added', 'District has been successfully added.')
      } else {
        Vue.set(this.tableData, this.editIndex, s)
        this.showSuccess('District updated', 'District has been successfully saved.')
      }
      this.$store.dispatch('getLocationData', { limit: -1 })
    },
    deleteDistrict (idx, district) {
      let self = this
      let name = district.district
      this.editIndex = idx
      self.$confirm('This will permanently delete ' + name + '. Continue?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        districtService.removeDistrict(district)
          .then(() => {
            self.tableData.splice(self.editIndex, 1)
            self.title = 'Districts (' + (this.tableTotal - 1) + ')'
            self.editIndex = null
            self.$store.dispatch('getLocationData', { limit: -1 })
            self.showSuccess('Success', name + ' Deleted')
          })
          .catch(() => {
            self.editIndex = null
            return false
          })
      }).catch(() => { })
    },
    filterByRegion (v) {
      this.regionFilter = v.value
      this.getDistricts()
    },
    getParams (info) {
      return {
        page: info.page,
        limit: info.pageSize,
        sort_field: is.not.undefined(info.sort) && is.not.undefined(info.sort.prop) && is.not.empty(info.sort.prop) ? info.sort.prop : 'district',
        sort_order: is.not.undefined(info.sort) && is.not.undefined(info.sort.order) && is.not.empty(info.sort.order) ? info.sort.order : 'ascending',
        search: (info.type === 'filter') ? (is.not.undefined(info.filters[0]) ? info.filters[0]['value'] : '') : '',
        region: this.regionFilter === 0 ? '' : this.regionFilter
      }
    },
    getSimpleDistrict (district) {
      return district
    }
  }
}
</script>
