<template>
  <el-form ref='form' :model="data" :rules='rules'>
    <div class="form-row">
      <el-form-item label="District" prop="districtCode" :error="errors.districtCode" class="col-md-6">
        <el-select v-model="data.districtCode" name="districtCode" placeholder="Select District"  class="w-100">
          <el-option v-for="(t, i) in districts" :key="i" :label="t.label + ' (' + t.region_name + ')'" :value="t.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Name" prop="Name" :error="errors.Name" class="col-md-6">
        <el-input v-model="data.Name" name="name" placeholder="Enter group name" autocomplete="off"></el-input>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Supervisor" prop="supervisor_id" :error="errors.supervisor_id" class="col-md-6">
        <el-select v-model="data.supervisor_id" name="supervisor_id" placeholder="Select Supervisor"  class="w-100">
          <el-option v-for="(t, i) in supervisors" :key="i" :label="t.label" :value="t.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Enumerators" prop="team_ids" :error="errors.team_ids" class="col-md-6">
        <el-select v-model="data.team_ids" multiple  placeholder="Select Enumerators" class="w-100">
            <el-option v-for="(t, i) in available_enumerators" :key="i" :label="t.label" :value="t.id" :disabled="in_group(t)"></el-option>
        </el-select>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Description" prop="Description" :error="errors.Description" class="col-md-12">
        <el-input type="textarea" name="description" v-model="data.Description" rows="2" placeholder="Enter group description"></el-input>
      </el-form-item>
    </div>

  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import is from 'is_js'
import { bus } from '../../../main'

export default {
  name: 'GroupForm',
  props: {
    inStateOne: Boolean
  },
  data () {
    let validateName = (rule, value, callback) => {
      if (!is.empty(value)) {
        callback()
      } else {
        callback(new Error('Group name is required.'))
      }
    }
    let validateDesc = (rule, value, callback) => {
      if (is.not.empty(value)) {
        callback()
      } else {
        callback(new Error('Description is required.'))
      }
    }
    return {
      data: {},
      orig_team: [],
      rules: {
        districtCode: [
          { required: true, message: 'District cannot be empty', trigger: 'change' }
        ],
        name: [
          { required: true, type: 'string', message: 'Name cannot be empty', trigger: 'blur' },
          { validator: validateName, trigger: 'change' }
        ],
        description: [
          { required: true, message: 'Notes can not be empty', trigger: 'blur' },
          { validator: validateDesc, trigger: 'blur' }
        ]
      },
      errors: {
        Name: '',
        districtCode: '',
        Assigned: '',
        Size: '',
        Description: '',
        createuserid: '',
        supervisor_id: '',
        team_ids: ''
      }
    }
  },
  watch: {
    data: {
      handler (val) {
        let self = this
        val.team_ids.forEach(function (id) {
          self.orig_team.push(id)
        })
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      supervisors: 'getSupervisorDD',
      enumerators: 'getEnumeratorDD',
      districts: 'getDistrictsDropdown'
    }),
    available_enumerators: function () {
      return this.enumerators.map(function (x) {
        return x
      })
    },
    defaultData: function () {
      return {
        Name: '',
        districtCode: '',
        Assigned: '',
        Description: '',
        createuserid: '',
        Size: '',
        supervisor_id: '',
        team_ids: ''
      }
    }
  },
  created () {
    bus.$on('sserror', (data) => {
      for (let x in this.errors) {
        this.errors[x] = ''
      }
      if (is.existy(data) && is.existy(data.errors)) {
        let errors = data.errors
        for (let x in errors) {
          this.errors[x] = errors[x][0]
        }
      }
    })
  },
  methods: {
    in_group (e) {
      if (is.inArray(e.id, this.data.team_ids))
        return true
      return is.inArray(e.id, this.orig_team) ? false : e.in_group
    }
  }
}
</script>
