import RegionForm from './RegionForm'
import { createFormDialog } from 'el-form-dialog'
import service from '../../../api/locations'
import { bus } from '../../../main'

export default createFormDialog(
  {
    stateOneTitle: 'Add Region',
    stateTwoTitle: 'Edit Region',
    confirmText: 'Ok',
    cancelText: 'Cancel',
    async confirm(data) {
      this.showLoading()

      try {
        if (this.inStateOne) {
          await this.addRegion(data)
        } else {
          await this.updateRegion(data)
        }
      } catch (e) {
        this.syslog(e)
      }
    }
  },
  {
    methods: {
      addRegion (data) {
        let self = this
        return service.addRegion(data)
          .then((response) => {
            this.$emit('updated', response.data)
            self.hideLoading()
            self.closeDialog()
          })
          .catch((error) => {
            this.hideLoading()
            bus.$emit('sserror', error)
          })
      },
      updateRegion (data) {
        let self = this
        return service.updateRegion(data)
          .then((response) => {
            this.$emit('updated', response)
            self.hideLoading()
            self.closeDialog()
          })
          .catch((error) => {
            this.hideLoading()
            bus.$emit('sserror', error)
          })
      }
    }
  }
)(RegionForm)