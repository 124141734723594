<template>
  <el-form ref="form" :model="idata" :rules="rules">
    <div class="form-row">
      <el-form-item label="Firm" prop="firm_id" :error="errors.firm_id" class="col-md-6">
        <el-select v-model="idata.firm_id" name="firm_id" placeholder="Select Firm" class="w-100">
          <el-option v-for="(r, i) in available_firms" :key="i" :label="r.label" :value="r.id"></el-option>
        </el-select>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Role" prop="account_type" :error="errors.account_type" class="col-md-6">
        <el-select v-model="idata.account_type" name="account_type" placeholder="Select account type" class="w-100">
          <el-option v-for="(r, i) in available_roles" :key="i" :label="r.label" :value="r.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Full Name" prop="name" :error="errors.name" class="col-md-6">
        <el-input v-model="idata.name" name="name" placeholder="Enter your full name" autocomplete="off"></el-input>
      </el-form-item>
    </div>

    <div class="form-row" v-show="canShowSupervisorFirms">
      <el-form-item label="Firms to supervise" prop="supervisor_firm_ids" :error="errors.supervisor_firm_ids" class="col-md-12">
        <el-select v-model="idata.supervisor_firm_ids" name="supervisor_firm_ids" multiple placeholder="Select Firms" class="w-100">
          <el-option v-for="(t, i) in available_firms" :key="i" :label="t.label" :value="t.id"></el-option>
        </el-select>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Email" prop="email" :error="errors.email" class="col-md-6">
        <el-input v-model="idata.email" name="email" placeholder="Email address" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="Phone Number" prop="mobile" :error="errors.mobile" class="col-md-6">
        <el-input v-model="idata.mobile" name="mobile" placeholder="Enter a valid Ghana number" autocomplete="off"></el-input>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Password" prop="password" :error="errors.password" class="col-md-6">
        <el-input type="password" name="password" v-model="idata.password" placeholder="Password" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="Confirm" prop="password_confirmation" class="col-md-6">
        <el-input type="password" name="password_confirmation" v-model="idata.password_confirmation" placeholder="Password confirmation" autocomplete="off" :disabled="!hasPassword"></el-input>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Description" prop="description" :error="errors.description" class="col-md-12">
        <el-input type="textarea" name="description" v-model="idata.description" rows="3" placeholder="Enter a description for the user"></el-input>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import is from 'is_js'
import { bus } from '../../../main'

export default {
  name: 'UserForm',
  props: {
    inStateOne: Boolean,
    firm: Number,
    mdata: Object
  },
  data() {
    let validateName = (rule, value, callback) => {
      if (!is.empty(value) && value.indexOf(' ') !== -1) {
        callback()
      } else {
        callback(new Error('Full name is required. E.g. Firstname Surname.'))
      }
    }
    let validateMobile = (rule, value, callback) => {
      let reg = /^\d+$/
      if (is.startWith(value, '0') && value.length == 10 && reg.test(value)) {
        callback()
      } else {
        callback(new Error('Phone number must be a valid Ghana phone number.'))
      }
    }
    let validatePass = (rule, value, callback) => {
      if (this.inStateOne) {
        if (is.not.empty(value) && is.not.undefined(value) && value.length > 5) {
          if (this.idata.password_confirmation !== '') {
            this.$refs.form.validateField('password_confirmation')
          }
          callback()
        } else {
          callback(new Error('Invalid password. Password must be 6 or more characters.'))
        }
      } else {
        if (value === '' || is.undefined(value)) {
          callback()
        } else if (is.not.empty(value) && is.not.undefined(value) && value.length > 5) {
          if (this.idata.password_confirmation !== '') {
            this.$refs.form.validateField('password_confirmation')
          }
          callback()
        } else {
          callback(new Error('Invalid password. Password must be 6 or more characters.'))
        }
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (!this.inStateOne) {
        if (is.undefined(this.idata.password) || this.idata.password === '') {
          callback()
        }
      }
      if (value !== this.idata.password) {
        callback(new Error('This does not match the password.'))
      } else {
        callback()
      }
    }
    let validateEmail = (rule, value, callback) => {
      let e = new Error('Email required for all users except Supervisors & Enumerators')
      if (is.empty(this.idata.account_type) || this.idata.account_type === '') {
        callback(e)
      } else if (is.inArray(this.idata.account_type, [1, 2, 3, 6, 7]) && value === '') {
        callback(e)
      } else {
        callback()
      }
    }
    return {
      idata: {
        supervisor_firm_ids: []
      },
      orig_supv_firms: [],
      rules: {
        account_type: [{ required: true, message: 'Account type cannot be empty', trigger: 'change' }],
        name: [
          { required: true, type: 'string', message: 'Name cannot be empty', trigger: 'blur' },
          { validator: validateName, trigger: 'change' }
        ],
        mobile: [
          { required: true, type: 'string', message: 'Phone can not be empty', trigger: 'blur' },
          { validator: validateMobile, trigger: 'change' }
        ],
        email: [{ validator: validateEmail, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'change' }],
        password_confirmation: [{ validator: validatePass2, trigger: 'change' }],
        description: [{ message: 'Description can not be empty', trigger: 'blur' }]
      },
      errors: {
        account_type: '',
        supervisor_firm_ids: '',
        name: '',
        email: '',
        mobile: '',
        password: '',
        description: ''
      }
    }
  },
  watch: {
    inStateOne: {
      handler(v) {
        if (!v) {
          this.init()
        }
      }
    },
    mdata: {
      handler() {
        this.init()
      },
      deep: true
    },
    idata: {
      handler(val) {
        let self = this
        if (is.not.undefined(val.supervisor_firm_ids)) {
          val.supervisor_firm_ids.forEach(function (id) {
            self.orig_supv_firms.push(id)
          })
        }
        this.idata.is_admin = is.not.undefined(this.idata.account_type) && this.idata.account_type === 1
        this.data = this.idata
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      roles: 'getRolesDropdown',
      firms: 'getFirmsDropdown',
      isSuperAdmin: 'isSuperAdmin',
      user: 'getUser',
      isGnhr: 'isGnhr'
    }),
    available_roles: function () {
      let self = this
      return this.roles.filter(function (r) {
        let go = self.isSuperAdmin ? r.id < 3 || r.id >= 6 : true
        go = self.isSuperAdmin && self.idata.firm_id !== 1 && r.id === 2 ? false : go
        return r.id !== 0 && go
      })
    },
    available_firms: function () {
      let self = this
      return this.firms.filter(function (v) {
        return self.isGnhr ? v.id !== 0 : v.id === self.user.firm_id
      })
    },
    hasPassword: function () {
      return is.not.undefined(this.idata.password) && is.not.empty(this.idata.password)
    },
    canShowSupervisorFirms: function () {
      return this.isGnhr && is.not.undefined(this.idata.account_type) && this.idata.account_type === 7
    }
  },
  created() {
    bus.$on('sserror', (data) => {
      for (let x in this.errors) {
        this.errors[x] = ''
      }
      if (is.existy(data) && is.existy(data.errors)) {
        let errors = data.errors
        for (let x in errors) {
          this.errors[x] = errors[x][0]
        }
      }
    })
  },
  methods: {
    init() {
      if (!this.inStateOne) {
        let self = this
        let r = this.roles.filter(function (r) {
          return r.label === self.mdata.role
        })
        let ac = r.length === 0 ? null : r[0].id
        this.idata = {
          firm_id: is.not.undefined(this.mdata.firm_id) ? this.mdata.firm_id : 1,
          account_type: is.not.undefined(this.mdata.role) ? ac : null,
          name: is.not.undefined(this.mdata.name) ? this.mdata.name : '',
          supervisor_firm_ids: is.not.undefined(this.mdata.supervisor_firm_ids) ? this.mdata.supervisor_firm_ids : [],
          email: is.not.undefined(this.mdata.email) ? this.mdata.email : '',
          mobile: is.not.undefined(this.mdata.mobile) ? this.mdata.mobile : '',
          description: is.not.undefined(this.mdata.description) ? this.mdata.description : '',
          userID: is.not.undefined(this.mdata.userID) ? this.mdata.userID : ''
        }
        // console.log('Created')
        // console.log(this.mdata)
        // console.log(this.idata)
      }
    }
  }
}
</script>
