<template>
  <el-form ref='form' :model="data" :rules='rules'>
    <div class="form-row">
      <el-form-item label="District" prop="districtCode" :error="errors.districtCode" class="col-md-12">
        <el-select v-model="data.districtCode" name="districtCode" placeholder="Select District"  class="w-100">
          <el-option v-for="(t, i) in available_districts" :key="i" :label="t.label + ' (' + t.region_name + ')'" :value="t.id"></el-option>
        </el-select>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Name" prop="name" :error="errors.community" class="col-md-12">
        <el-input v-model="data.community" name="community" placeholder="Enter community name" autocomplete="off"></el-input>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="GPS Location" prop="gpsLocation" :error="errors.gpsLocation" class="col-md-12">
        <el-input v-model="data.gpsLocation" name="gpsLocation" placeholder="Enter community GPS location" autocomplete="off"></el-input>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Enumeration Goal" prop="enumerationGoal" :error="errors.enumerationGoal" class="col-md-6">
        <el-input type="number" min="0" v-model="data.enumerationGoal" name="enumerationGoal" placeholder="Enter enumeration goal" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="GSS Household Count" prop="gssHouseholdCount" :error="errors.gssHouseholdCount" class="col-md-6">
        <el-input type="number" min="0" v-model="data.gssHouseholdCount" name="gssHouseholdCount" placeholder="Enter GSS count" autocomplete="off"></el-input>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Notes" prop="Notes" :error="errors.notes" class="col-md-12">
        <el-input type="textarea" name="notes" v-model="data.notes" rows="5" placeholder="Enter a notes for the community"></el-input>
      </el-form-item>
    </div>

  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import is from 'is_js'
import { bus } from '../../../main'

export default {
  name: 'CommunityForm',
  props: {
    inStateOne: Boolean
  },
  data () {
    let validateName = (rule, value, callback) => {
      if (!is.empty(value)) {
        callback()
      } else {
        callback(new Error('Community name is required.'))
      }
    }
    let validateDesc = (rule, value, callback) => {
      if (is.not.empty(value)) {
        callback()
      } else {
        callback(new Error('Notes is required.'))
      }
    }
    return {
      data: {},
      rules: {
        districtCode: [
          { required: true, message: 'District cannot be empty', trigger: 'change' }
        ],
        community: [
          { required: true, type: 'string', message: 'Name cannot be empty', trigger: 'blur' },
          { validator: validateName, trigger: 'change' }
        ],
        notes: [
          { required: true, message: 'Notes can not be empty', trigger: 'blur' },
          { validator: validateDesc, trigger: 'blur' }
        ]
      },
      errors: {
        districtCode: '',
        communityCode: '',
        community: '',
        gpsLocation: '',
        enumerationGoal: '',
        gssHouseholdCount: '',
        notes: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      districts: 'getDistrictsDropdown'
    }),
    available_districts: function () {
      return this.districts.filter(function(d)  {
        return d.id !== 0
      })
    },
    defaultData: function () {
      return {
        districtCode: '',
        communityCode: '',
        community: '',
        gpsLocation: '',
        enumerationGoal: '',
        gssHouseholdCount: '',
        notes: ''
      }
    }
  },
  created () {
    bus.$on('sserror', (data) => {
      for (let x in this.errors) {
        this.errors[x] = ''
      }
      if (is.existy(data) && is.existy(data.errors)) {
        let errors = data.errors
        for (let x in errors) {
          this.errors[x] = errors[x][0]
        }
      }
    })
  }
}
</script>
