<template>
  <el-form ref='form' :model="data" :rules='rules'>
    <div class="form-row">
      <el-form-item label="Region" prop="regionCode" :error="errors.regionCode" class="col-md-12">
        <el-select v-model="data.regionCode" name="regionCode" placeholder="Select Region"  class="w-100">
          <el-option v-for="(t, i) in available_regions" :key="i" :label="t.label" :value="t.id"></el-option>
        </el-select>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Name" prop="name" :error="errors.district" class="col-md-12">
        <el-input v-model="data.district" name="district" placeholder="Enter district name" autocomplete="off"></el-input>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Capital" prop="districtCapital" :error="errors.districtCapital" class="col-md-12">
        <el-input v-model="data.districtCapital" name="name" placeholder="Enter district capital name" autocomplete="off"></el-input>
      </el-form-item>
    </div>

    <div class="form-row">
      <el-form-item label="Notes" prop="Notes" :error="errors.notes" class="col-md-12">
        <el-input type="textarea" name="notes" v-model="data.notes" rows="5" placeholder="Enter a notes for the district"></el-input>
      </el-form-item>
    </div>

  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import is from 'is_js'
import { bus } from '../../../main'

export default {
  name: 'DistrictForm',
  props: {
    inStateOne: Boolean
  },
  data () {
    let validateName = (rule, value, callback) => {
      if (!is.empty(value)) {
        callback()
      } else {
        callback(new Error('District name is required.'))
      }
    }
    let validateCap = (rule, value, callback) => {
      if (!is.empty(value)) {
        callback()
      } else {
        callback(new Error('Capital name is required.'))
      }
    }
    let validateDesc = (rule, value, callback) => {
      if (is.not.empty(value)) {
        callback()
      } else {
        callback(new Error('Notes is required.'))
      }
    }
    return {
      data: {},
      rules: {
        regionCode: [
          { required: true, message: 'Region cannot be empty', trigger: 'change' }
        ],
        district: [
          { required: true, type: 'string', message: 'Name cannot be empty', trigger: 'blur' },
          { validator: validateName, trigger: 'change' }
        ],
        districtCapital: [
          { required: true, type: 'string', message: 'Capital cannot be empty', trigger: 'blur' },
          { validator: validateCap, trigger: 'change' }
        ],
        notes: [
          { required: true, message: 'Notes can not be empty', trigger: 'blur' },
          { validator: validateDesc, trigger: 'blur' }
        ]
      },
      errors: {
        districtCode: '',
        regionCode: '',
        district: '',
        districtCapital: '',
        notes: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      regions: 'getRegionsDropdown'
    }),
    available_regions: function () {
      return this.regions.filter(function(r)  {
        return r.id !== 0
      })
    },
    defaultData: function () {
      return {
        districtCode: '',
        regionCode: '',
        district: '',
        districtCapital: '',
        notes: ''
      }
    }
  },
  created () {
    bus.$on('sserror', (data) => {
      for (let x in this.errors) {
        this.errors[x] = ''
      }
      if (is.existy(data) && is.existy(data.errors)) {
        let errors = data.errors
        for (let x in errors) {
          this.errors[x] = errors[x][0]
        }
      }
    })
  }
}
</script>
