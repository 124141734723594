<template>
  <div style="margin: 0; padding: 0">
    <PageHeader subtitle="Manage Groups" :title="title" />

    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom">
            <div class="row">
              <div class="col-sm-2" v-if="isGnhr">
                <FirmFilter v-on:firm_change="filterByFirm" v-show="isGnhr" />
              </div>
              <div class="col-sm-3">
                <DistrictFilter v-on:district_change="filterByDistrict" />
              </div>
              <div class="col-sm-3">
                <div class="btn-group float-right" data-toggle="buttons" aria-label>
                  <el-input v-model="tableFilteredData[0].value" placeholder="Search" prefix-icon="el-icon-search" size="medium" style="width: 250px"></el-input>
                </div>
              </div>
              <div class="col-sm-4 hidden-sm-down">
                <div class="btn-toolbar float-right" role="toolbar" aria-label="Toolbar with button groups">
                  <el-button type="primary" size="small" :disabled="isGnhr" @click="addGroup()"><i class="el-icon-plus el-icon--left"></i> Add New Group</el-button>
                  <el-dropdown @command="handleBulkAction">
                    <el-button class="ml-3" :disabled="isGnhr" type="primary"> Bulk Upload<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="upload"> <i class="material-icons">cloud_upload</i>Upload file</el-dropdown-item>
                      <el-dropdown-item command="download"> <i class="material-icons">cloud_download</i> Download template</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body p-4 pb-3">
            <data-tables-server :loading="busy" :data="tableData" :total="tableTotal" :filters="tableFilteredData" @query-change="get" :table-props="tableProps">
              <el-table-column v-for="(item, idx) in visibleFields" :key="idx" :prop="item.prop" :label="item.label" :sortable="item.sortable" :width="item.width || ''">
                <template slot-scope="scope">
                  <span>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Actions">
                <template slot-scope="scope">
                  <el-button :disabled="isGnhr" @click="editGroup(scope.$index, scope.row)" type="primary" size="mini">Edit</el-button>
                  <el-button :disabled="isGnhr" @click="deleteGroup(scope.$index, scope.row)" type="danger" size="mini">Delete</el-button>
                </template>
              </el-table-column>
            </data-tables-server>
          </div>
        </div>
      </div>
    </div>

    <group-form :data="editData" :in-state-one="addState" :visible.sync="dialogOpen" :dialogProps="{ closeOnClickModal: false }" v-on:updated="addEditUpdate"> </group-form>

    <!-- Bulk modal -->
    <el-dialog width="40%" title="Upload Groups" :close-on-click-modal="false" :visible.sync="bulkDialogVisible" :before-close="handleClose">
      <vue-csv-import
        :url="bulkUploadUrl"
        :callback="uploadSuccess"
        :catch="uploadFailed"
        loadBtnText="Upload"
        submitBtnText="Submit"
        v-model="bulkCsv"
        :map-fields="bulkFields"
        :headers="true"
      ></vue-csv-import>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="handleClose()">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import is from 'is_js'
import { VueCsvImport } from 'vue-csv-import'
import { DataTablesServer } from 'vue-data-tables'
let numeral = require('numeral')

import { EndPoints } from '../../api/api'
import enumeratorService from '../../api/user'
import PageHeader from '../../containers/PageHeader'
import FirmFilter from '../../components/filters/FirmFilter'
import DistrictFilter from '../../components/filters/DistrictFilter'
import GroupForm from '../../components/forms/GroupForm'

Vue.use(DataTablesServer)
Vue.use(require('vue-moment'))

export default {
  name: 'Groups',
  components: {
    PageHeader,
    FirmFilter,
    DistrictFilter,
    GroupForm,
    VueCsvImport
  },
  computed: {
    ...mapGetters({
      isGnhr: 'isGnhr',
      isAdmin: 'isAdmin',
      user: 'getUser'
    }),
    visibleFields: function () {
      return this.tableFields.filter(function (f) {
        return f.visible
      })
    },
    title: function () {
      return 'Groups (' + numeral(this.tableTotal).format('0,0') + ')'
    }
  },
  data() {
    return {
      busy: true,
      addState: true,
      dialogOpen: false,
      bulkDialogVisible: false,
      bulkUploadUrl: '',
      bulkFields: { Name: 'Name*', districtCode: 'District Code*', mobile: 'Supervisor Mobile', Description: 'Description' },
      bulkCsv: [],
      editData: {},
      editIndex: null,
      sortProps: { order: 'ascending', prop: 'Name' },
      tableData: [],
      tableTotal: 0,
      tableFields: [],
      tableFilteredData: [
        {
          value: '',
          prop: ['fullname', 'fname']
        }
      ],
      tableProps: {
        style: 'width: 99%',
        emptyText: 'No groups found',
        stripe: true,
        defaultSort: this.sortProps
      },
      viewIgnoreFields: ['fullname', 'mobile', 'emailAddress', 'ezwich'],
      firmFilter: '',
      districtFilter: '',
      selectedItem: null,
      queryProps: {
        page: 1,
        pageSize: 10,
        sort: this.sortProps,
        search: '',
        firm: '',
        district: ''
      }
    }
  },
  created() {
    this.bulkUploadUrl = EndPoints.GROUPS_UPLOAD_URL
    this.assignFilter = 'unassigned'
    this.firmFilter = this.isGnhr ? 0 : this.user.firm_id
    this.tableFields = [
      { prop: 'firm_id', label: 'Firm', visible: false, sortable: true },
      { prop: 'fname', label: 'Firm', visible: this.isGnhr, sortable: 'custom' },
      { prop: 'district', label: 'District', visible: true, sortable: 'custom' },
      { prop: 'GroupID', label: 'ID', visible: true, sortable: false },
      { prop: 'Name', label: 'Name', visible: true, sortable: 'custom' },
      { prop: 'supervisor', label: 'Supervisor', visible: true, sortable: false },
      { prop: 'team', label: 'Team', visible: true, sortable: false },
      { prop: 'supervisor_id', label: 'Supervisor', visible: false, sortable: false },
      { prop: 'districtCode', label: 'District Code', visible: false, sortable: false },
      { prop: 'team_ids', label: 'Teams', visible: false, sortable: false }
    ]
    this.get()
  },
  methods: {
    get(queryInfo = {}) {
      let self = this
      this.busy = true
      this.queryProps = queryInfo
      let params = this.getParams(queryInfo)
      enumeratorService
        .getGroups(params)
        .then((response) => {
          self.tableData = response.data.map(function (c) {
            return self.getSimpleRow(c)
          })
          self.tableTotal = response.meta.total
          self.busy = false
        })
        .catch((error) => {
          this.busy = false
          this.handleError(error)
        })
    },
    filterByDistrict(v) {
      this.districtFilter = v.value
      this.get(this.queryProps)
    },
    filterByFirm(v) {
      this.firmFilter = v.value
      this.get(this.queryProps)
    },
    addGroup() {
      this.addState = true
      this.dialogOpen = true
    },
    editGroup(idx, group) {
      this.editIndex = idx
      this.editData = group
      this.addState = false
      this.dialogOpen = true
    },
    addEditUpdate(group) {
      let s = this.getSimpleRow(group)
      if (this.addState) {
        this.tableData.unshift(s)
        this.showSuccess('Group added', 'Group has been successfully added.')
      } else {
        Vue.set(this.tableData, this.editIndex, s)
        this.showSuccess('Group updated', 'Group has been successfully saved.')
      }
    },
    deleteGroup(idx, group) {
      let self = this
      let name = group.Name
      this.editIndex = idx
      self
        .$confirm('This will permanently delete ' + name + '. Continue?', 'Warning', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        })
        .then(() => {
          enumeratorService
            .deleteGroup(group)
            .then(() => {
              self.tableData.splice(self.editIndex, 1)
              self.editIndex = null
              self.showSuccess('Success', name + ' Deleted')
              self.get()
            })
            .catch(() => {
              self.editIndex = null
              return false
            })
        })
        .catch(() => {})
    },
    getParams(info) {
      return {
        page: info.page,
        limit: info.pageSize,
        sort_field: is.not.undefined(info.sort) && is.not.undefined(info.sort.prop) && is.not.empty(info.sort.prop) ? info.sort.prop : this.sortProps.prop,
        sort_order: is.not.undefined(info.sort) && is.not.undefined(info.sort.order) && is.not.empty(info.sort.order) ? info.sort.order : this.sortProps.order,
        search: info.type === 'filter' ? (is.not.undefined(info.filters[0]) ? info.filters[0]['value'] : '') : '',
        firm: this.firmFilter === 0 ? '' : this.firmFilter,
        district: this.districtFilter === 0 ? '' : this.districtFilter
      }
    },
    getSimpleRow(item) {
      let x = {}
      for (let idx in this.tableFields) {
        let i = this.tableFields[idx]
        x[i.prop] = is.existy(i.date) && is.not.null(item[i.prop]) ? this.$moment(item[i.prop]).format('MMM Do, YYYY') : item[i.prop]
        x[i.prop] = is.existy(i.numeric) && is.not.null(item[i.prop]) ? numeral(item[i.prop]).format('0,0') : x[i.prop]
      }
      return x
    },
    // Event handlers
    handleBulkAction(val) {
      if (val === 'upload') {
        this.bulkDialogVisible = true
      } else if (val === 'download') {
        this.downloadCsv()
      }
    },
    downloadCsv() {
      let csvContent = 'data:text/csv;charset=utf-8' //,Error Code,Question,Message,Date,Last Checked\n'
      for (let x in this.bulkFields) {
        csvContent += ',' + this.bulkFields[x]
      }
      csvContent += '\n'
      let encodedUri = encodeURI(csvContent)
      let link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'GNHR Enumeration Group Template.csv')
      document.body.appendChild(link)
      link.click()
    },
    uploadSuccess() {
      this.$message({ type: 'success', message: 'Upload successful.' })
      this.bulkDialogVisible = false
      this.get()
    },
    uploadFailed(e) {
      let msg = 'An error occured.'
      if (is.array(e.response.data.error)) {
        msg = e.response.data.error.join('<br><br/>')
      } else {
        msg = e.response.data.error
      }
      msg = 'Error uploading file:<br><br>' + msg
      this.$message({ dangerouslyUseHTMLString: true, type: 'error', message: msg, duration: 5000 })
    },
    handleClose(done) {
      if (is.function(done)) {
        done()
      }
      this.bulkCsv = []
      this.bulkDialogVisible = false
    }
  }
}
</script>
<style>
h5.modal-title {
  color: white !important;
}
.text-light {
  color: white !important;
}
</style>
