<template>
  <div style="margin: 0; padding: 0">
    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom">
            <div class="row float-left">
              <div class="col-sm-6">
                <b-form-group label="" label-cols-sm="8" label-align-sm="left" label-for="match_select" label-class="font-weight-bolder">
                  <model-list-select
                    id="match_select"
                    name="match_select"
                    :list="[
                      { id: 1, label: 'All' },
                      { id: 2, label: 'Selected' },
                      { id: 3, label: 'Unselected' }
                    ]"
                    v-model="selectedFilter"
                    placeholder="Select Match"
                    option-value="id"
                    option-text="label"
                  ></model-list-select>
                </b-form-group>
              </div>
              <div class="col-sm-4">
                <div class="btn-group mr-1 mt-3" data-toggle="buttons" aria-label="">
                  <el-input v-model="searchFilter" placeholder="Search" prefix-icon="el-icon-search" size="medium" style="width: 300px"></el-input>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body p-4 pb-3">
            <data-tables-server :loading="busy" :data="tableData" :total="tableTotal" :table-props="tableProps" :pagination-props="tablePaginationProps" :pageSize="300">
              <el-table-column align="left" label="Selected" width="100px">
                <template slot-scope="scope">
                  <b-form-checkbox
                    @change="handleClick(scope.row)"
                    v-model="scope.row.checked"
                    :id="setRowId(scope.$index)"
                    :name="setRowId(scope.$index)"
                    value="true"
                    unchecked-value="false"
                  ></b-form-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="code" label="Code" sortable="custom" width="150px"></el-table-column>
              <el-table-column prop="description" label="Description"></el-table-column>
            </data-tables-server>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import is from 'is_js'
import { DataTablesServer } from 'vue-data-tables'
import { ModelListSelect } from 'vue-search-select'

import surveyService from '../../api/survey'

Vue.use(DataTablesServer)

export default {
  name: 'QAQuestionManager',
  components: { ModelListSelect },
  props: {
    type: {
      type: String,
      default: ''
    },
    questions: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      busy: false,
      title: '',
      emptyText: 'No data found',
      allData: [],
      selected: {},
      selectedFilter: 1,
      searchFilter: '',
      tableData: [],
      tableTotal: 0,
      tableFilteredData: [
        {
          value: '',
          prop: ['code', 'description']
        }
      ],
      tableProps: {
        style: 'width: 99%',
        emptyText: 'No data found',
        stripe: true,
        pageSize: 100,
        defaultSort: { order: 'ascending', prop: 'code' }
      },
      tablePaginationProps: { pageSizes: [20, 50, 100, 300] }
    }
  },
  created() {
    this.title = this.isMember() ? 'Member' : 'Household'
    this.getQuestionCodes()
  },
  watch: {
    busy: function (v) {
      this.$emit('busy', { type: this.type, value: v })
    },
    title: function (v) {
      this.$emit('title', { type: this.type, value: v })
    },
    searchFilter: function () {
      this.setData()
    },
    selectedFilter: function () {
      this.setData()
    }
  },
  methods: {
    getQuestionCodes() {
      if (!this.busy) {
        let self = this
        this.busy = true
        surveyService
          .getQaQuestionCodes()
          .then((response) => {
            self.setSelectedCodes(response.data)
            self.allData = self.questions
            self.setData()
            self.setTitle()
            self.busy = false
          })
          .catch((error) => {
            this.busy = false
            console.log(error)
          })
      }
    },
    updateQuestionCodes() {
      if (!this.busy) {
        let self = this
        this.busy = true
        let codes = this.getSelectedCodes(true)
        let params = this.isMember() ? { members: codes } : { household: codes }
        surveyService
          .updateQaQuestionCodes(params)
          .then(() => {
            self.busy = false
          })
          .catch((error) => {
            this.busy = false
            console.log(error)
          })
      }
    },
    handleClick(row) {
      this.selected[row.code] = row.checked === 'true' ? true : false
      this.setTitle()
      this.updateQuestionCodes()
      console.log(this.title + ' ' + row.code + ': ' + this.selected[row.code])
    },
    setSelectedCodes(codes) {
      let self = this
      const x = this.isMember() ? codes[0].members.split(',') : codes[0].household.split(',')
      const y = x.map((s) => s.trim())
      this.questions.forEach(function (q) {
        self.selected[q.code] = is.inArray(q.code, y)
      })
    },
    getSelectedCodes(asString = false) {
      let self = this
      const x = Object.keys(this.selected)
        .filter((key) => self.selected[key] === true)
        .sort(function (a, b) {
          return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
        })
      return asString ? x.join(',') : x
    },
    getSelectedLen() {
      const x = this.getSelectedCodes()
      return x.length
    },
    setData() {
      let self = this
      this.tableData = this.allData
        .map(function (e) {
          e.checked = self.selected[e.code]
          return e
        })
        .filter(function (e) {
          let goFilter = self.selectedFilter === 1 ? true : self.selectedFilter === 2 ? e.checked === true : e.checked === false
          let goSearch = e.code.includes(self.searchFilter)
          return goFilter && goSearch
        })
        .sort(function (a, b) {
          return a.code.localeCompare(b.code, undefined, { numeric: true, sensitivity: 'base' })
        })
      this.tableTotal = this.tableData.length
    },
    setTitle() {
      let sel = this.getSelectedLen()
      this.title = (this.isMember() ? 'Member' : 'Household') + ' (' + sel + '/' + this.tableTotal + ')'
    },
    setRowId(n) {
      return 'checkbox-' + this.type + '-' + n
    },
    isMember() {
      return this.type === 'member'
    }
  }
}
</script>
