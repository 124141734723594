<template>
  <div style="margin: 0; padding: 0">
    <PageHeader subtitle="Manage Users" :title="title" />

    <div class="row">
      <div class="col">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom">
            <div class="row">
              <div v-show="isSuperAdmin" class="col-sm-2">
                <FirmFilter v-on:firm_change="filterByFirm" :id="firmFilter" :inclGnhr="true" />
              </div>
              <div class="col-sm-2">
                <RoleFilter v-on:role_change="filterByRole" />
              </div>
              <div class="col-sm-3">
                <div class="btn-group mr-1" data-toggle="buttons" aria-label="">
                  <el-input v-model="tableFilteredData[0].value" placeholder="Search" prefix-icon="el-icon-search" size="medium" style="width: 300px"></el-input>
                </div>
              </div>
              <div class="col-sm-5 hidden-sm-down">
                <div class="btn-toolbar float-right" role="toolbar" aria-label="Toolbar with button groups">
                  <el-button type="primary" size="mini" @click="addUser()"><i class="el-icon-plus el-icon--left"></i> Add New User</el-button>
                  <el-dropdown @command="handleBulkAction">
                    <el-button class="ml-3" type="primary"> Bulk Upload<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="upload"> <i class="material-icons">cloud_upload</i>Upload file</el-dropdown-item>
                      <el-dropdown-item command="download"> <i class="material-icons">cloud_download</i> Download template</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body p-4 pb-3">
            <data-tables-server :loading="busy" :data="tableData" :total="tableTotal" :filters="tableFilteredData" @query-change="getUsers" :table-props="tableProps">
              <el-table-column prop="role" label="Role" sortable="custom"></el-table-column>
              <el-table-column prop="name" label="Name" sortable="custom"></el-table-column>
              <el-table-column prop="email" label="Email" sortable="custom"></el-table-column>
              <el-table-column prop="mobile" label="Phone Number" sortable="custom"></el-table-column>
              <el-table-column prop="district" label="District" sortable="custom">
                <template slot-scope="scope">
                  <span v-if="scope.row.role === 'Coordinator'">
                    {{ scope.row.assigned ? scope.row.district : 'Unassigned' }}<br /><br />
                    <el-button
                      v-if="scope.row.role === 'Coordinator'"
                      size="mini"
                      icon="el-icon-location-outline"
                      type="success"
                      title="Assign District"
                      @click="showAssignDistrict(scope.$index, scope.row)"
                    >
                      {{ scope.row.assigned ? 'Change District' : 'Assign to Distict' }}
                    </el-button>
                  </span>
                  <span v-else>{{ isOps(scope.row.role) ? 'All' : isEmpty(scope.row.district) ? 'Unassigned' : scope.row.district }}</span>
                </template>
              </el-table-column>

              <el-table-column align="left" label="">
                <template slot-scope="scope">
                  <el-button size="mini" icon="el-icon-edit" type="primary" title="Edit user" @click="editUser(scope.$index, scope.row)" :disabled="!isEditable(scope.row)"></el-button>
                  <el-button size="mini" icon="el-icon-delete" type="danger" title="Delete user" @click="deleteUser(scope.$index, scope.row)" :disabled="!isEditable(scope.row)"></el-button>
                </template>
              </el-table-column>
            </data-tables-server>
          </div>
        </div>
      </div>
    </div>

    <user-form :mdata.sync="editData" :firm="userFirm" :in-state-one.sync="addState" :visible.sync="dialogOpen" :dialogProps="{ closeOnClickModal: false }" v-on:updated="addEditUpdate"> </user-form>

    <!-- Bulk modal -->
    <el-dialog width="40%" title="Upload Users" :close-on-click-modal="false" :visible.sync="bulkDialogVisible" :before-close="handleClose">
      <vue-csv-import
        :url="bulkUploadUrl"
        :callback="uploadSuccess"
        :catch="uploadFailed"
        loadBtnText="Upload"
        submitBtnText="Submit"
        v-model="bulkCsv"
        :map-fields="bulkFields"
        :headers="true"
      ></vue-csv-import>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="handleClose()">Cancel</el-button>
      </span>
    </el-dialog>

    <!-- District modal -->
    <el-dialog title="Assign District" :visible.sync="assignDialogVisible" width="30%">
      <SelectDistrict :id="selector_id" :value="district_id" :firm="firmFilter" v-model="district_id" />
      <span slot="footer" class="dialog-footer">
        <b-button size="sm" class="px-4" variant="light" @click="assignDialogVisible = false">Cancel</b-button>
        <b-button size="sm" class variant="primary" :disabled="districtAssignDisabled" @click="assignDistrict()">Assign</b-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import is from 'is_js'
import PageHeader from '../../containers/PageHeader'
import UserForm from '../../components/forms/UserForm'
import { VueCsvImport } from 'vue-csv-import'
import { DataTablesServer } from 'vue-data-tables'
import userService from '../../api/user'
import { EndPoints } from '../../api/api'

import RoleFilter from '../../components/filters/RoleFilter'
import FirmFilter from '../../components/filters/FirmFilter'
import SelectDistrict from '../../components/selectors/SelectDistrict'

Vue.use(DataTablesServer)

export default {
  name: 'UserManagement',
  components: {
    PageHeader,
    UserForm,
    RoleFilter,
    FirmFilter,
    SelectDistrict,
    VueCsvImport
  },
  data() {
    return {
      busy: true,
      addState: true,
      dialogOpen: false,
      bulkDialogVisible: false,
      bulkUploadUrl: '',
      bulkFields: {
        firm_id: 'Firm ID*',
        role: 'Role*',
        fullname: 'Name*',
        mobile: 'Mobile*',
        GroupID: 'Group ID',
        emailAddress: 'Email',
        description: 'Description',
        ezwich: 'Ezwich',
        gender: 'Gender (1:Male 2:Female)',
        dob: 'Date of Birth'
      },
      bulkCsv: [],
      editData: {},
      editIndex: null,
      emptyText: 'No data found',
      tableData: [],
      tableTotal: 0,
      tableFilteredData: [
        {
          value: '',
          prop: ['name', 'email', 'mobile', 'description', 'role']
        }
      ],
      tableProps: { style: 'width: 99%', emptyText: 'No users found', stripe: true, defaultSort: { order: 'ascending', prop: 'name' } },
      queryProps: { page: 1, pageSize: 10, sort: { order: 'ascending', prop: 'name' }, search: '' },
      roleFilter: 0,
      firmFilter: 0,
      district_id: 0,
      selector_id: 0,
      selectedItem: null,
      assignDialogVisible: false
    }
  },
  created() {
    this.bulkUploadUrl = EndPoints.USERS_UPLOAD_URL
    this.firmFilter = this.userFirm
    this.getUsers()
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'isSuperAdmin',
      isAdmin: 'isAdmin',
      myUser: 'getUser',
      userFirm: 'getUserFirm',
      roles: 'getRoles'
    }),
    title: function () {
      return 'Users (' + this.tableTotal + ')'
    },
    districtAssignDisabled: function () {
      return this.district_id === 0 || is.null(this.district_id)
    }
  },
  methods: {
    getUsers(queryInfo = {}) {
      let self = this
      this.busy = true
      let params = this.getParams(queryInfo)
      userService
        .getUsers(params)
        .then((response) => {
          self.tableData = response.data.map(function (s) {
            return self.getSimpleUser(s)
          })
          self.tableTotal = response.meta.total
          self.busy = false
        })
        .catch((error) => {
          this.busy = false
          this.handleError(error)
        })
    },
    addUser() {
      this.addState = true
      this.dialogOpen = true
    },
    editUser(idx, user) {
      this.editIndex = idx
      this.editData = user
      this.addState = false
      this.dialogOpen = true
    },
    addEditUpdate(user) {
      let s = this.getSimpleUser(user)
      if (this.addState) {
        this.tableData.unshift(s)
        this.showSuccess('User added', 'User has been successfully added.')
      } else {
        Vue.set(this.tableData, this.editIndex, s)
        this.showSuccess('User updated', 'User has been successfully saved.')
      }
      this.$store.dispatch('getPeople', { limit: -1, firm: this.userFirm })
      this.dialogOpen = false
    },
    deleteUser(idx, user) {
      let self = this
      let name = user.name
      this.editIndex = idx
      self
        .$confirm('This will permanently delete ' + name + '. Continue?', 'Warning', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        })
        .then(() => {
          userService
            .removeUser(user)
            .then(() => {
              self.tableData.splice(self.editIndex, 1)
              self.editIndex = null
              self.showSuccess('Success', name + ' Deleted')
            })
            .catch(() => {
              self.editIndex = null
              return false
            })
        })
        .catch(() => {})
    },
    showAssignDistrict(idx, item) {
      this.selector_id = idx
      this.district_id = item.assigned ? item.districtCode : 0
      this.selectedItem = item
      this.assignDialogVisible = true
    },
    assignDistrict() {
      let self = this
      let payload = {
        userID: this.selectedItem.userID,
        districtCode: this.district_id
      }
      userService
        .updateCoordinator(payload)
        .then(() => {
          self.district_id = 0
          self.selectedItem = null
          self.selector_id = null
          self.assignDialogVisible = false
          self.showSuccess('Success', 'Coordinator updated')
          self.getUsers()
        })
        .catch((error) => {
          self.handleError(error)
          return false
        })
    },
    isEditable(user) {
      return (user.firm_id === this.userFirm && this.isAdmin) || this.isSuperAdmin
    },
    filterByRole(v) {
      this.roleFilter = v.value
      this.getUsers()
    },
    filterByFirm(v) {
      this.firmFilter = v.value
      this.getUsers()
    },
    getParams(info) {
      return {
        page: info.page,
        limit: info.pageSize,
        sort_field: is.not.undefined(info.sort) && is.not.undefined(info.sort.prop) && is.not.empty(info.sort.prop) ? info.sort.prop : 'fullname',
        sort_order: is.not.undefined(info.sort) && is.not.undefined(info.sort.order) && is.not.empty(info.sort.order) ? info.sort.order : 'ascending',
        search: info.type === 'filter' ? (is.not.undefined(info.filters[0]) ? info.filters[0]['value'] : '') : '',
        firm: this.firmFilter === 0 ? '' : this.firmFilter,
        role: this.roleFilter === 0 ? '' : this.roleFilter
      }
    },
    getSimpleUser(user) {
      let x = {}
      x.userID = user.userID
      x.firm_id = user.firm_id
      x.name = user.fullname
      x.email = user.emailAddress
      x.mobile = user.mobile
      x.description = user.description
      x.role = user.role
      x.district = user.district
      x.districtCode = user.districtCode
      x.assigned = user.assigned
      x.account_type = user.accountType
      x.is_admin = user.role === 'Administrator'
      x.supervisor_firm_ids = user.supervisor_firm_ids
      return x
    },
    isOps(role) {
      return is.not.inArray(role, ['Supervisor', 'Enumerator'])
    },
    isEmpty(x) {
      return is.null(x) || is.undefined(x) || x === ''
    },
    // Event handlers
    handleBulkAction(val) {
      if (val === 'upload') {
        this.bulkDialogVisible = true
      } else if (val === 'download') {
        this.downloadCsv()
      }
    },
    downloadCsv() {
      let self = this
      let csvContent = 'data:text/csv;charset=utf-8'
      for (let x in this.bulkFields) {
        csvContent += ',' + this.bulkFields[x]
      }
      csvContent += '\n'
      csvContent += this.roles
        .map(function (d) {
          return self.userFirm + ',' + d.role
        })
        .join('\n')
        .replace(/(^\{)|(\}$)/gm, '')
      let encodedUri = encodeURI(csvContent)
      let link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'GNHR Users Template.csv')
      document.body.appendChild(link)
      link.click()
    },
    uploadSuccess() {
      this.$message({ type: 'success', message: 'Upload successful.' })
      this.bulkDialogVisible = false
      this.getUsers()
    },
    uploadFailed(e) {
      let msg = 'An error occured.'
      if (is.array(e.response.data.error)) {
        msg = e.response.data.error.join('<br><br/>')
      } else {
        msg = e.response.data.error
      }
      msg = 'Error uploading file:<br><br>' + msg
      this.$message({ dangerouslyUseHTMLString: true, type: 'error', message: msg, duration: 5000 })
    },
    handleClose(done) {
      if (is.function(done)) {
        done()
      }
      this.bulkCsv = []
      this.bulkDialogVisible = false
    }
  }
}
</script>
